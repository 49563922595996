<template>
    <div class="to-left control-col">
        <div class="flex collapse-fold-icon">
            <div v-if="isHeader || !row._detail" class="not-show" />
            <div
                v-else
                :class="getCollapseIcon()"
                @click.stop="toggleCollapse()"
            />
            <div v-if="!isHeader && !hasAnyGrandchild" class="not-show" />
            <div v-else :class="getFoldIcon()" @click.stop="toggleFold()" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CollapseController',
    emit: ['update:isCollapsed', 'update:isFolded', 'fold'],
    components: {},
    props: {
        isHeader: {
            type: Boolean,
            default: false
        },
        row: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isCollapsed: {
            type: Boolean,
            default: false
        },
        isFolded: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggleCollapse: function () {
            this.isCollapsedValue = !this.isCollapsedValue
        },
        toggleFold: function () {
            this.isFoldedValue = !this.isFoldedValue
            this.isCollapsedValue = false
        },
        getCollapseIcon: function () {
            return `toggle-menu-icon ${this.isCollapsedValue ? 'collapse' : ''}`
        },
        getFoldIcon: function () {
            return `${this.isFoldedValue ? 'un' : ''}fold-icon`
        }
    },
    computed: {
        isCollapsedValue: {
            get() {
                return this.isCollapsed
            },
            set(val) {
                this.$emit('update:isCollapsed', val)
            }
        },
        isFoldedValue: {
            get() {
                return this.isFolded
            },
            set(val) {
                this.$emit('update:isFolded', val)
                this.$emit('set-fold', val)
            }
        },
        hasAnyGrandchild: function () {
            if (!this.row._detail) return false
            return this.row._detail.some((rows) => rows._detail !== undefined)
        }
    },
    watch: {}
}
</script>

<style lang="scss" scoped>
// collapse
.control-col {
    grid-row: span 2;
    width: 60px;
    box-sizing: border-box;
    padding: 0;
    min-height: inherit;
    position: relative;

    .collapse-fold-icon {
        position: absolute;
        z-index: 3;
    }
}

div:nth-of-type(2) {
    justify-content: left;
}

@for $i from 1 through 15 {
    .deep-#{$i} .control-col {
        padding-left: 15px * $i;
    }
}

.fold-icon,
.unfold-icon {
    cursor: pointer;
    width: 12px;
    height: 12px;
}

.toggle-menu-icon {
    height: 12px;
    width: 12px;
    margin-right: 15px;
    transition: transform 0.3s;
    transform: rotate(90deg);
    cursor: pointer;
    &.collapse {
        transform: rotate(0deg);
    }
}

.not-show {
    display: block;
    height: 12px;
    width: 12px;
    margin-right: 15px;
}

// checkbox
.checkbox-col {
    width: 2%;
}
</style>
